.container, .header {
  margin: 0;
  padding: 0;
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
  object-fit: cover; /* Ensures the image covers the entire screen */
  background-size: cover; /* Stretches the background to cover the entire viewport */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents image repetition */
}


.logoContainer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 18vh;
}

.logo {
  z-index: -1;
  height: 30vh;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-size: 6vh;
  margin: 0; /* Ensure no extra margin is added */
  padding-top: 6vh; /* Add padding above */
  padding-bottom: 6vh; /* Add padding below */
  line-height: 1; /* Optional: Ensure no extra spacing is caused by line height */
}

.container {
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; /* Adjust for space between rows */
  flex-wrap: wrap; /* Wrap categories on smaller screens */
}

.category {
  flex: 1;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.2); /* White background with 80% opacity */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Make the boxes take up full height */
  margin: 0 10px;
}

.category h2 {
  background: linear-gradient(-90deg,  #3380ff,#224080); /* Gradient at -45 degrees */
  color: #ffffff;
  padding: 10px 0;
  margin: 0;
  text-align: center;
  font-size: 2.5vh; /* Adjust font size to fit the screen */
  border-top-left-radius: 10px; /* Rounded top-left corner */
  border-top-right-radius: 10px; /* Rounded top-right corner */
}


.linkList {
  list-style-type: none;
  padding: 20px;
  flex-grow: 1;
}

.linkList li {
  padding: 1vh;
  color: #fff;
  font-size: 2.2vh; /* Adjusted font size */
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

@media screen and (max-width: 768px) {
  .category {
    margin-bottom: 20px; /* Add space between boxes on small screens */
  }
}
