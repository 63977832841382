.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
}

.logoContainer {
  position: absolute;
  width: 100vw;
  display: grid;
  grid-template-columns: 78% 20% 2%;
  gap: 0px 0px;
}

.logoContainer_01 {
  width: 100vw;
  display: flex;
  align-items: flex-end;
  gap: 50px;
  height: 10%;
  padding-left: 5vh;
}
.logoContainer_01 p {
  font-size: 4cqmin;
  position: relative;
  top: -2.5cqmin;
  margin: 0px;
  color: #ffff;
  
}

.logo {
  z-index: -1;
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 1vh;
}

.awslogo {
  z-index: -1;
  width: 100%;
  padding-top: 5vh;
}

.homeButton {
  background-color: #53d7fb;
  display: inline;
  padding: 10px;
  position: fixed;
  top: 94vh;
  left: 1vw;
  height: 2vh;
  width: 2vh;
  border-radius: 50%;
  cursor: pointer;
}
.visitus {
  background-color: black;
  display: inline;
  padding: 10px;
  position: fixed;
  top: 80vh;
  right: 20vw;
  width: 13vh;
}

.learnmore {
  background-color: black;
  display: inline;
  padding: 10px;
  position: fixed;
  top: 80vh;
  right: 10vw;
  width: 13vh;
}

.homeButton > img {
  cursor: pointer;
}

img {
  cursor: default;
}

.slideNavigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  border-radius: 50%;
  background-color: #bbbbbb;
  height: 3vh;
  width: 3vh;
  text-align: center;
  margin: 10px 20px 0 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button > * {
  cursor: pointer;
}

.box {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.container {
  width: 90vw;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 10px 5vw;
}

.sublogo {
  grid-area: 1 / 1 / 2 / 2;
}
.space {
  grid-area: 3 / 1 / 4 / 3;
  height: 100px;
}

.header {
  grid-area: 2 / 1 / 3 / 2;
  color: #ffffff;
  font-family: 'Poppins-Bold';
  font-size: 4vh;
  margin: 0 0 0 0vw;
}
.slides {
  grid-area: 4 / 1 / 5 / 2;
  animation-name: example;
  animation-duration: 1s;
}
.video {
  grid-area: 4 / 2 / 5 / 3;
}
.control {
  grid-area: 5 / 1 / 6 / 2;
}

.aws {
  grid-area: 5 / 2 / 6 / 3;
}

.pause {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 16px;
  height: 2px;
  background: currentColor;
  border-radius: 10px;
  margin-top: -6px;
  box-shadow: 0 6px 0;
}

@keyframes example {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
