.navigation {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.navButton {
  margin: 30px;
  background-color: #53d7fb;
  padding: 10px;
  height: 2vh;
  width: 2vh;
  border-radius: 50%;
  cursor: pointer;
}

.navButton > img {
  cursor: pointer;
}

.navigation > :nth-child(1) {
  background-color: #FFB233;
}
.navigation > :nth-child(3) {
  background-color: #b00b86;
}
