.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
}

.logoContainer {
  position: fixed;
  width: 100vw;
  display: grid;
  grid-template-columns: 78% 20% 2%;
  gap: 0px 0px;
}

.logo {
  z-index: -1;
  width: 100%;
  padding-top: 5vh;
  visibility: hidden;
}

.container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: space-evenly;
  padding-top: 10vh;
}

.row {
  display: flex;
  flex-direction: column;
  width: 45vw;
  align-items: center;
}

.row > p {
  width: 100%;
  font-size: 4vh;
  color: white;
  text-align: center;
  font-family: 'Poppins-Bold';
  padding: 1vh 0 1vh 0;
  margin: 0;
}

.row > h3 {
  width: 100%;
  font-size: 3vh;
  color: white;
  text-align: center;
  font-family: 'Poppins-Light';
  padding: 1vh 0 1vh 0;
  height: 8vh;
}

.row > div {
  width: 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
}

.imagePage1 {
  grid-area: 1 / 1 / 2 / 2;
}

.imagePage2 {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  justify-content: center;
}

.imagePage2 > img {
  cursor: pointer;
}
