.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 0px 0px;
  width: 100vw;
  margin-top: 1vh;
}

.header01 {
  color: #ffff;
  grid-area: 1 / 1 / 2 / 2;
  text-align: center;
  font-size: 2vh;
}
.header02 {
  color: #ffff;
  grid-area: 1 / 2 / 2 / 3;
  text-align: center;
  font-size: 2vh;
}
.video01 {
  grid-area: 2 / 1 / 3 / 2;
  margin: 10px;
}
.video02 {
  grid-area: 2 / 2 / 3 / 3;
  margin: 10px;
}

.verticalContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
}
.header {
  text-align: center;
  font-size: 2vh;
  margin: 10px;
}
.videoContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  width: 91%;
}
.player {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 1;
}
.clock {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
}
.qrcode {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 3;
  align-self: end;
  justify-self: end;
  margin: 0 20px 20px 0;
}

.info {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 3;
  align-self: end;
  justify-self: end;
  margin: 0 20px 100px 0;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  gap: 20px;
}

.latencyInfo {
  font-family: 'VeraMono.ttf';
  background-color: #ffff;
  font-size: 2vh;
  padding: 10px;
  /* margin-bottom: 30px; */
}
