.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  height: 100vh;
  width: 100vw;
  color: #ffff;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.label {
  font-size: 2rem;
}

.error {
  color: red;
  font-size: 2rem;
  margin-top: 40px;
}

.logoutContainer {
  top: 3vh;
  left: 95vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
