@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'),
    url('./fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins-Thin'),
    url('./fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'),
    url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'),
    url('./fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: local('Roboto-Medium'),
    url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'AnonymousPro-Bold';
  src: local('AnonymousPro-Bold'),
    url('./fonts/Anonymous_Pro/AnonymousPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'VeraMono.ttf';
  src: local('VeraMono.ttf'),
    url('./fonts/Bitstream-Vera-Sans-Mono/VeraMono.ttf') format('truetype');
}

body {
  background-color: black;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: 'Poppins-Medium';
}
