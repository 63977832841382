.screenCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: flex-start; /* Align items to the top */
  overflow: hidden;
  background-color: #000; /* Fallback color */
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
}

.logoContainer {
  position: fixed;
  width: 100vw;
  display: grid;
  grid-template-columns: 78% 20% 2%;
  gap: 0px 0px;
  padding-top: 2vh;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align logos and text */
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
}

.title {
  display: flex;
  flex-direction: column; /* Stack title and subtitle vertically */
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 8vh;
  padding-bottom: 3vh;
}

.title_1 {
  color: white;
  font-size: 6vh;
  font-family: 'Poppins-Bold';
  height: 7.5vh;
}

.subtitle {
  font-family: 'Poppins', sans-serif;  /* Use Poppins font */
  font-size: 2.5vh;  /* Adjusted size to be smaller than the title */
  font-weight: 400;  /* Regular weight */
  color: #ffffff;  /* White color */
  text-align: center;  /* Center align the text */
  margin-top: 1vh;  /* Margin to separate from the title */
  max-width: 80vw;  /* Constrain the width to avoid overflow on smaller screens */
  line-height: 1.4;  /* Line height for better readability */
}

.container {
  display: flex;
  justify-content: center;
  width: 100vw;
  column-gap: 2vw;
}

.box {
  display: flex;
  flex-direction: column;
  width: 40vw;
  justify-content: center;
  align-items: center;
  row-gap: 1vw;
}

.player {
  width: 100%;
  /* height: 50vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.bottom {
  width: 100%;
  color: white;
  font-size: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins-Light';
}

.logo {
  height: 8vh; /* Adjust height as needed */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure logos are not stretched */
  margin-left: 2vw;
  margin-right: 2vw;
  padding-top: 2vh;
}

.logo:first-child {
  margin-left: 4vw;  
}

.logo:last-child {
  margin-right: 4vw;  
}

.adjustButton {  
  bottom: 10vh; /* Adjust based on your design */
  right: 5vw; /* Adjust based on your design */
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ff6b6b;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  cursor: pointer;
}

.adjustButton:hover {
  background-color: #ff4949;
}

.adjustButton svg {
  color: #fff;
  font-size: 24px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  padding: 20px;
  width: 500px; /* Increased width of the modal */
  max-width: 90%; /* Ensure it doesn't overflow on smaller screens */
  text-align: center;
  position: absolute;
}



.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

h7 {
  font-family: 'Poppins', sans-serif;
  color: black;
  margin-bottom: 20px;
}

.urlInput {
  width: 90%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.modalButtons {
  display: flex;
  justify-content: space-between;
}

.modalButton {
  width: 23%; /* Adjusted width to fit four buttons */
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.modalButton:hover {
  background-color: #0056b3;
}

/* Container for the switch, center it horizontally */
.switchContainer {
  display: flex;
  justify-content: center;  /* Horizontally center the switch */
  align-items: center;      /* Vertically align content */
  margin-top: 20px;         /* Adjust the top margin as needed */
  width: 100%;              /* Ensure full width for centering */
}

/* Adjust the switch label and input for proper alignment */
.switchLabel {
  display: flex;
  align-items: center;      /* Vertically align label and switch */
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

/* Hide the default checkbox */
.switchInput {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.switchSlider {
  position: relative;
  width: 50px;
  height: 24px;
  background-color: #ccc;
  border-radius: 34px;
  margin-left: 10px;        /* Spacing between label and switch */
  transition: 0.4s;
}

/* The circle inside the slider */
.switchSlider::before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

/* When the checkbox is checked, change background color */
.switchInput:checked + .switchSlider {
  background-color: #2196F3;
}

/* Move the circle to the right when checked */
.switchInput:checked + .switchSlider::before {
  transform: translateX(26px);
}
