.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
}

.logoContainer {
  position: fixed;
  width: 100vw;
  display: grid;
  grid-template-columns: 78% 20% 2%;
  gap: 0px 0px;
  padding-top: 2vh;
}

.logo {
  z-index: -1;
  height: 8vh;
}

.title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  padding-top: 5vh;
  padding-bottom: 1vh;
}

.title_1 {
  color: white;
  font-size: 6vh;
  font-family: 'Poppins-Bold';
  height: 7.5vh;
}

.container {
  /* margin-top: 2vh; */
  /* margin-bottom: 1vh; */
  width: 100vw;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 1vw;
  justify-content: space-evenly;
  height: 70vh;
}
.rightSide {
  width: 49vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leftSide {
  width: 49vw;
}



.schedular {
  height: 40vh;
  width: 2000px;
}

.dashboardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: flex-start; */
  /* align-items: flex-end;
  align-self: flex-end; */
  height: 70vh;
  width: 48vw;
  padding: 0 0 0 30px;
}

.dashboardHeader {
  color: #ffff;
  font-size: 2vh;
  align-items: center;
  height: 4vh;
}


.playerHeader {
  color: #ffff;
  font-size: 2vh;
  align-items: center;
  height: 4vh;
}

.screenCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: first baseline;
}

.adImage {
  height: 20%;
  object-fit: 'cover';
}

.player {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 70%;
}

.playerWindow {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 1;
}

.wallclock {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
  align-self: end;
  justify-self: center;
  color: white;
  font-size: 1.3vw;
  font-family: 'Arial Narrow';
  font-weight: 100;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.videoClock {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
  align-self: end;
  justify-self: center;
  color: white;
  font-size: 1.3vw;
  font-family: 'Arial Narrow';
  font-weight: 100;
  letter-spacing: 2px;
  margin-bottom: 100px;
  background-color: black;
  padding: 0px 10px 0px 10px;
}

.cockpit {
  background: #b00c86;
  color: #ffff;
  text-align: center;
  font-size: 2vh;
  padding: 1vh 0 1vh 0;
}

/* Styling for the first row, if it has any specific styles */
.highlightstitle {
  /* Your styles here */
  display: flex;
  align-items: center;
  height: 10vh;
  font-size: 30px;
}

/* Styling for the second row to hold images */
.highlights {
  display: flex;
  justify-content: left; /* Adjust this as necessary, e.g., 'space-between' */
  align-items: center; /* This will vertically center the images if the row is taller than images */
  height: auto; /* Height will be determined by the content */
  background-color: #111217;
  padding: 10px;
  width: 46.5vw;
  height: 8vh;
  border-radius: 10px; /* This adds rounded corners; adjust as necessary */
}

/* Styling for the images */
.responsiveImage {
  max-width: 18%; /* Adjust the percentage so that five images fit in the row */
  height: auto; /* Maintain aspect ratio */
  max-height: 6vh;
  padding: 0; /* No padding */
  padding-right: 1vw; /* padding to the right  */
}

.item {
  border: black 2px solid;
  border-radius: 10px; /* This adds rounded corners; adjust as necessary */
  /* text-align: center; */
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns children to the top */
  padding-top: 10px; /* Adds padding at the top */
  align-items: left;
  font-family: 'Roboto-Medium';
  font-size: 30px;
  color: rgb(204, 204, 220);
  /* padding-top: 10px;
  padding: 10px; */
  /* height: auto; */
  text-align: left;
  width: 100%;
  padding: 20px 0 20px 0;
}

.panel {
  justify-self: flex-start;
  align-self: flex-start;
  font-size: calc(0.7vw);
}
