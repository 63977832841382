.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  height: 100vh;
  width: 60vw;
  font-size: 2vh;
  margin-left: auto;
  margin-right: auto;
  color:white;
}

.list {
  list-style-type: none;
}

.list > li {
  margin-top: 10px;
  color:white;
  list-style: disc;
}



.offlineInstallation {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff; /* Change as needed */
  margin: 0 20px; /* Space between the icons */
}

.iconContainer {
  background-color: #fff; /* Light background to ensure visibility */
  border-radius: 10px;    /* Optional: for rounded corners */
  padding: 10px;
}

.icon {
  width: 100px;
  height: 100px;
  display: block;
}