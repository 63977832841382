.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 14vh;
}

.subtitle01 {
  color: white;
  font-size: 6vh;
  font-family: 'Poppins-Bold';
  height: 7.5vh;
}

.playerTitle {
  color: #ffff;
  font-size: 3vh;
  text-align: center;
  padding: 1vh 0 1vh 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 95vw;
  margin-left: auto;
  margin-right: auto;
  grid-column-gap: 30px;
}

.startButton {
  color: 'red';
  width: 300px;
}

/* .svgContainer {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.svgContainer svg {
  fill: red;
} */

.svgContainer {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 100px;
  height: 100px;
  border: 5px solid #52b3cb;
  border-radius: 4px;
  margin: 10px;
}

.svgContainer::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 70px;
  border-top: 34px solid transparent;
  border-bottom: 34px solid transparent;
  border-left: 50px solid #52b3cb;
  top: 11px;
  left: 23px;
}

.svgContainer:hover {
  cursor: pointer;
}

.actionButton {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
}
