.screenCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: flex-start; /* Align items to the top */
  overflow: hidden;
  background-color: #000; /* Fallback color */
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: cover;
}

.logoContainer {
  position: fixed;
  width: 100vw;
  display: grid;
  grid-template-columns: 78% 20% 2%;
  gap: 0px 0px;
  padding-top: 2vh;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align logos and text */
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 5vh;
  position: relative;
}

.title {
  display: flex;
  flex-direction: column; /* Stack title and subtitle vertically */
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 8vh;
  padding-bottom: 3vh;
}

.title_1 {
  color: white;
  font-size: 6vh;
  font-family: 'Poppins-Bold';
  height: 7.5vh;
}

.subtitle {
  font-family: 'Poppins', sans-serif;  /* Use Poppins font */
  font-size: 2.5vh;  /* Adjusted size to be smaller than the title */
  font-weight: 400;  /* Regular weight */
  color: #ffffff;  /* White color */
  text-align: center;  /* Center align the text */
  margin-top: 1vh;  /* Margin to separate from the title */
  max-width: 80vw;  /* Constrain the width to avoid overflow on smaller screens */
  line-height: 1.4;  /* Line height for better readability */
}

.container {
  display: flex;
  justify-content: center;
  width: 100vw;
  column-gap: 2vw;
}

.box {
  display: flex;
  flex-direction: column;
  width: 40vw;
  justify-content: center;
  align-items: center;
  row-gap: 1vw;
}

.player {
  width: 100%;
  /* height: 50vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.bottom {
  width: 100%;
  color: white;
  font-size: 3vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins-Light';
}

.logo {
  height: 8vh; /* Adjust height as needed */
  width: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure logos are not stretched */
  margin-left: 2vw;
  margin-right: 2vw;
  padding-top: 2vh;
}

.logo:first-child {
  margin-left: 4vw;  
}

.logo:last-child {
  margin-right: 4vw;  
}
