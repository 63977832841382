
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7vh;
}
.title {
  color: white;
  font-size: 7vh;
  font-weight: bold;
}

.subtitle {
  color: white;
  font-size: 4.8vh;
}

.box {
  width: 45vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30px;
}

.controls {
  display: flex;
  justify-content: space-evenly;
  column-gap: 5px;
}


.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 100vh;
}

.videocontainer {
  position: relative;

  width: calc(50% - 0.1vh); /* Adjusted for 2x2 grid */
  height: calc(50vh - 0.1vh); /* Adjusted for 2x2 grid */
  box-sizing: border-box;
  margin: 0.05vh; /* Reduced margin to create a thin gap */

}

.videocontainer:nth-child(2),
.videocontainer:nth-child(3) {
  /* Remove the margin between player 2 and 3 to only have a thin line */
  margin-left: 0.05vh;
}

video {
  width: 100%;
  height: 100%;
}

.overlaytext {
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translateX(-50%);
  width: 20%;
  text-align: center;
  color: white;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.5);
  padding: 1vh;
  border-radius: 1vh;
}

.metric {
  font-size: 1.5vh; /* Adjusted font size for the metric text */
}

/* Use a media query to reduce the font size for smaller windows */
@media (max-width: 720px) {
  .score {
      font-size: 3vw; /* Smaller font size for smaller windows */
  }
}

/* For larger windows, maintain a larger font size */
@media (min-width: 720px) {
  .score {
      font-size: 4vh; /* Larger font size for larger windows */
  }
}