.cloudDVR {
  background-color: black;
  background-image: url('../StartPage/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  position: relative; /* Changed from z-index: -5 to relative for positioning */
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 2vh;
}

.header h1 {
  color: white;
  font-size: 4vh;
  font-weight: bold;
  margin: 0;
  text-align: center;
  flex-grow: 1; /* Ensures the title takes up all available space */
}

.header .logo {
  position: absolute;
  right: 2%;
  top: 0;
  height: 8vh;
  z-index: 1;
}

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
}

.gridContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  gap: 2vh;
  width: 90%;
  height: 80%;
  background-image: url('./images/cDVR_Diagram_gradient_noarrow.svg');
  background-size: 92%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 4vh;
}

.gridItem {
  background-color: rgba(255, 255, 255, 0.0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: black;
  font-family: Arial, sans-serif;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

/* Individual grid items to define their placement */
.item1 { grid-column: 1 / 2; grid-row: 1 / 2; }
.item2 { grid-column: 2 / 3; grid-row: 1 / 2; }
.item3 { grid-column: 3 / 4; grid-row: 1 / 2; }
.item4 { grid-column: 4 / 5; grid-row: 1 / 2; }
.item5 { grid-column: 5 / 6; grid-row: 1 / 2; }
.item6 { grid-column: 6 / 7; grid-row: 1 / 2; }

.item7 { grid-column: 1 / 2; grid-row: 2 / 4; } /* Merges rows 2 and 3 */
.item8 { grid-column: 2 / 3; grid-row: 2 / 3; } /* Limited to row 2 */
.item9 { grid-column: 3 / 4; grid-row: 2 / 4; } /* Merges rows 2 and 3 */
.item10 { grid-column: 4 / 5; grid-row: 2 / 4; } /* Merges rows 2 and 3 */
.item11 { grid-column: 5 / 6; grid-row: 2 / 4; } /* Merges rows 2 and 3 */
.item12 { grid-column: 6 / 7; grid-row: 2 / 4; } /* Merges rows 2 and 3 */

.item13 { grid-column: 2 / 3; grid-row: 3 / 4; } /* Limited to row 3 */

/* Transparent boxes */
.transparent {
  background-color: transparent;
  color: transparent;
  cursor: default;
}

/* Hover effect for clickable items */
.clickable:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Styling for the modal */
.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.modalContent {
  position: relative;
  background-color: black;
  margin: auto;
  padding: 0;
  width: 50%;
  height: auto;
  max-height: 90vh;
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  z-index: 20;
}
