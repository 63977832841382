.header {
  font-size: 4vh;
  color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 10vh;
}

.section {
  font-size: 2vh;
  color: #fff;
  padding-left: 5vw;
  padding-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70vw;
}

.element {
  background-color: #323232;
  padding: 20px;
  border-radius: 20px;
}

.link {
  margin-left: 20px;
  /* text-decoration: none; */
  color: #ffff;
}

.button {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
}

.info {
  font-size: 2vh;
  color: #fff;
  padding-left: 5vw;
  padding-top: 2vh;
  width: 70vw;
}

.header01 {
  font-family: 'Poppins-Bold';
  color: #307cf7;
  /* margin-left: 100px; */
}

/* .text {
  margin-left: 10px;
} */

.info > div,
.element > div {
  margin-left: 30px;
  display: list-item;
}
