/* .container {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  height: 100%;
  width: 100%;
} */

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* grid-template-rows: auto auto auto 50% auto; */
  /* height: 100%; */
  padding: auto;
}
.item,
.item2 {
  border: black 2px solid;
  border-radius: 10px; /* This adds rounded corners; adjust as necessary */
  /* text-align: center; */
  background: #191b1f;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns children to the top */
  padding-top: 10px; /* Adds padding at the top */
  align-items: center;
  font-family: 'Roboto-Medium';
  font-size: 30px;
  color: rgb(204, 204, 220);
  /* padding-top: 10px;
  padding: 10px; */
  /* height: auto; */
}

.item:nth-child(1) {
  grid-column: 1 / span 12;
  background-color: black;
  text-align: left;
  width: 100%;
  padding: 20px 0 20px 0;
}
.item:nth-child(2) {
  grid-column: 1 / span 4;
}
.item:nth-child(3) {
  grid-column: 5 / span 4;
}
.item:nth-child(4) {
  grid-column: 9 / span 4;
}

.item:nth-child(5) {
  grid-column: 1 / span 12;
  background-color: black;
  text-align: left;
  width: 100%;
  padding: 20px 0 20px 0;
}

.item:nth-child(6),
.item:nth-child(7),
.item:nth-child(8) {
  height: 20vh;
}

.item:nth-child(6),
.item2:nth-child(2) {
  grid-column: 1 / span 4;
}

.item:nth-child(7),
.item2:nth-child(3) {
  grid-column: 5 / span 4;
}

.item:nth-child(8),
.item2:nth-child(4) {
  grid-column: 9 / span 4;
}

.item:nth-child(9) {
  grid-column: 1 / span 4;
  background-color: black;
}
.item:nth-child(10) {
  grid-column: 5 / span 4;
  background-color: black;
}
.item:nth-child(11) {
  grid-column: 9 / span 4;
  background-color: black;
}

.item2:nth-child(1) {
  grid-column: 1 / span 12;
  background-color: black;
  text-align: left;
  width: 100%;
  padding: 20px 0 20px 0;
}

.dashboardHeader {
  color: #ffff;
  font-size: 2.5vh;
  align-items: center;
  height: 8%;
}

.gridServiceStatus {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}
.itemServiceStatus {
  text-align: center;
  font-family: 'Roboto-Medium';
  font-size: 30px;
  color: ccccdc;
  color: #ccccdc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.itemAlarmTitle {
  text-align: center;
  font-family: 'Roboto-Medium';
  font-size: 30px;
  color: #ccccdc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alarms {
  font-family: 'Roboto-Medium';
  font-size: calc(90% + 1vw);
  font-weight: bolder;
  color: #ccccdc;
  align-self: flex-end;
  vertical-align: center;
  min-height: 6vh;
}

.greeStatus {
  background-color: #1b6234;
  color: #ccccdc;
  text-align: center;
  padding-top: 2vh;
  border: black 2px solid;
}

.redStatus {
  background-color: #d44a3a;
  color: #ccccdc;
}

.itemServiceStatus:nth-child(1) {
  grid-column: 1 / span 2;
}

.itemServiceStatus:nth-child(4),
.itemServiceStatus:nth-child(5) {
  height: 0vh;
  font-size: calc(50% + 1vw);
}

.grafana {
  font-family: 'Roboto-Medium';
  font-size: calc(80% + 1vw);
  color: #ccccdc;
  /* padding-top: 1vh; */
}

.usageData {
  font-family: 'Roboto-Medium';
  font-size: calc(90% + 1vw);
  font-weight: bolder;
  color: ccccdc;
  align-items: center;
  padding-bottom: 1vh;
  padding-top: 1vh;
}

.savingslabel {
  font-family: 'Roboto-Medium';
  font-size: calc(8% + 1vw);
  font-weight: lighter;
  color: ccccdc;
  align-items: center;
  padding-bottom: 1vh;
  padding-top: 0vh;
}

.viewers {
  font-family: 'Roboto-Medium';
  font-size: calc(90% + 1vw);
  font-weight: bolder;
  color: ccccdc;
  align-items: center;
  padding-bottom: 2vh;
  /* padding-top: 1vh; */
}

.grafanaDescription {
  font-family: 'Roboto-Medium';
  font-size: calc(10% + 1vw);
  color: #ccccdc;
}

.thumbnail {
  width: 10vw;
  align-self: flex-end;
  margin-top: 1vh;
}

.panel {
  font-size: calc(0.9vw);
}

.blackout {
  position: relative;
  margin-top: 1vh;
}

.blackout::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensure the blackout covers the entire area */
  background-color: black;
  z-index: 100; /* Set a higher z-index to appear above the image */
}

.playerWindow {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.player {
  width: 576px;
  height: 324px;
}
.legendContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.box {
  width: 75px;
  height: 30px;
  margin: 0 50px 0 100px;
}
.green {
  background: rgba(21, 91, 20, 0.5);
}
.yellow {
  background: rgb(255, 204, 0, 0.5);
}
.blue {
  background: rgba(0, 58, 153, 0.5);
}
.legendContainerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vendorLogos {
  display: flex;
  flex-wrap: nowrap;
  width: 95%;
  justify-content: space-around;
  align-items: center;
  margin-top: 3vh;
  margin-left: 1vh;
  margin-right: 2vh;
  margin-bottom: 0vh;
  gap: 50px;
}

.thirdparty {
  font-size: 50px;
  color: #ffff;
  padding: 20px;
  background: #0481a3;
  border-radius: 20px;
}

/* Styling for the modal */
.modal {
  display: flex;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
}

.modalContent {
  position: relative;
  background-color: black;
  margin: auto;
  padding: 0;
  width: 50%;
  height: auto;
  max-height: 90vh;
  border-radius: 8px;
  overflow: hidden;
  z-index: 10;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #0000;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  z-index: 20;
}
