.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
}

.logoContainer {
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 80vh;
}

.logotext {
  color: white;
  font-size: 3.4vh;
  font-weight: bold;
  position: relative;
  top: -2vh;
}

.firstPageImage {
  width: 80vw;
  margin-bottom: 50px;
}

img {
  cursor: default;
}

.logoContainer > img {
  cursor: pointer;
}
