.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
}

.logoContainer {
  position: fixed;
  width: 100vw;
  display: grid;
  grid-template-columns: 78% 20% 2%;
  gap: 0px 0px;
  padding-top: 2vh;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align logos and text */
  width: 100%;
  padding-top: 5vh;
  padding-bottom: 1vh;
  position: relative;
}

.title {
  flex: 1; /* This makes the title container take up the remaining space */
  display: flex;
  justify-content: center;
  align-items: center; /* Vertically center the text */
  text-align: center;
}

.title_1 {
  color: white;
  font-size: 6vh;
  font-family: 'Poppins-Bold';
}

.logo {
  height: 8vh; /* Set a consistent height for logos */
  width: auto; /* Maintain the aspect ratio */
  object-fit: contain; /* Ensure logos are not stretched */
  margin-left: 2vw; /* Add space on the left of the logo */
  margin-right: 2vw; /* Add space on the right of the logo */
}

.logo:first-child {
  margin-left: 4vw; /* Increase space on the left logo from the screen edge */
}

.logo:last-child {
  margin-right: 4vw; /* Increase space on the right logo from the screen edge */
}


.container {
  /* margin-top: 2vh; */
  /* margin-bottom: 1vh; */
  width: 100vw;
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 1vw;
  justify-content: space-evenly;
  align-items: center;
}
.rightSide {
  width: 49vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.leftSide {
  width: 49vw;
}


.schedular {
  height: 40vh;
  width: 2000px;
}

.dashboardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: flex-start; */
  /* align-items: flex-end;
  align-self: flex-end; */
  height: 65vh;
  width: 48vw;
  padding: 0 30px 0 30px;
  background-color: black;
}

.switchButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 0px; /* Space between buttons and the dashboard container */
  margin-left: 30px; 
}

.switchButtons > div {
  color: #ffff;
  font-size: 2rem;
  background-color: black;
  width: 6vw;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border-top-left-radius: 10px; /* Rounds the top-left corner */
  border-top-right-radius: 10px; /* Rounds the top-right corner */
}
.switchButtons > div:hover {
  background-color: #0481A3;
}

.dashboardHeader {
  color: #ffff;
  font-size: 2vh;
  align-items: center;
  height: 4vh;
}

.playerContainer {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* height: auto;
  width: 47vw; */
}
.playerHeader {
  color: #ffff;
  font-size: 2vh;
  align-items: center;
  height: 4vh;
}

.screenCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: flex-start; /* Align items to the top */
}







.adImage {
  height: 20%;
  object-fit: 'cover';
}

.player {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 70%;
}

.playerWindow {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 1;
}

.wallclock {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
  align-self: end;
  justify-self: center;
  color: white;
  font-size: 1.3vw;
  font-family: 'Arial Narrow';
  font-weight: 100;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.videoClock {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
  align-self: end;
  justify-self: center;
  color: white;
  font-size: 1.3vw;
  font-family: 'Arial Narrow';
  font-weight: 100;
  letter-spacing: 2px;
  margin-bottom: 100px;
  background-color: black;
  padding: 0px 10px 0px 10px;
}

.cockpit {
  background: #b00c86;
  color: #ffff;
  text-align: center;
  font-size: 2vh;
  padding: 1vh 0 1vh 0;
}

/* Styling for the first row, if it has any specific styles */
.highlightstitle {
  /* Your styles here */
  diplay: flex;
  align-items: center;
  height: 10vh;
  font-size: 30px;
}

/* Styling for the second row to hold images */
.highlights {
  display: flex;
  justify-content: left; /* Adjust this as necessary, e.g., 'space-between' */
  align-items: center; /* This will vertically center the images if the row is taller than images */
  height: auto; /* Height will be determined by the content */
  background-color: #111217;
  padding: 10px;
  width: 46.5vw;
  height: 8vh;
  border-radius: 10px; /* This adds rounded corners; adjust as necessary */
}

/* Styling for the images */
.responsiveImage {
  max-width: 18%; /* Adjust the percentage so that five images fit in the row */
  height: auto; /* Maintain aspect ratio */
  max-height: 6vh;
  padding: 0; /* No padding */
  padding-right: 1vw; /* padding to the right  */
}

.item {
  border: black 2px solid;
  border-radius: 10px; /* This adds rounded corners; adjust as necessary */
  /* text-align: center; */
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns children to the top */
  padding-top: 10px; /* Adds padding at the top */
  align-items: left;
  font-family: 'Roboto-Medium';
  font-size: 30px;
  color: rgb(204, 204, 220);
  /* padding-top: 10px;
  padding: 10px; */
  /* height: auto; */
  text-align: left;
  width: 100%;
  padding: 20px 0 20px 0;
}

.panel {
  justify-self: flex-start;
  align-self: flex-start;
  font-size: calc(0.7vw);
}

.vendorLogos {
  display: flex;
  flex-wrap: nowrap;
  width: 95%;
  justify-content:space-around;
  align-items: center;
  margin-top: 3vh;
  margin-left: 1vh;
  margin-right: 2vh;
  margin-bottom: 0vh;
  gap: 50px;
}

.thirdparty {
  font-size: 50px;
  color: #ffff;
  padding: 20px;
  background: #0481A3;
  border-radius: 20px;
}

.infoPanel {
  display: flex;
  justify-content: space-around;
}

.infoPanel > div {
  color: #ffff;
  font-size: calc(0.9vw);
  margin-top: 10px;
}
